<template>
<div id="app" class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <nav id="site-navigation" class="has-branding main-navigation grid-container nav-align-right sub-menu-right" aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
    <div class="inside-navigation grid-container">
      <div class="navigation-branding">
        <div class="site-logo">
          <a href="https://gbwhatsapks.net/" title="GBWhatsApp" rel="home">
            <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/gb-logo.webp" title="GBWhatsApp">
          </a>
        </div>
        <p class="main-title" itemprop="headline">
          <a href="https://gbwhatsapks.net/" rel="home">
            GBWhatsApp
          </a>
        </p>
      </div>

      <div id="primary-menu" class="main-nav">
        <ul id="menu-primary-menu" class="menu sf-menu">
          <li id="menu-item-32" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
            <a href="https://gbwhatsapks.net/" aria-current="page">GB WhatsApp</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/download-gbwhatsapp/">Download</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/gb-whatsapp-old-versions/">Old Versions</a>
          </li>
          <li id="menu-item-33" class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
            <a href="https://gbwhatsapks.net/blogs/">Blogs</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
            <Breadcrumb :breadcrumbs="breadcrumbs" />
          <h1>Blogs</h1>
          <div class="blog-list-row">
            <a v-for="(data, i) in doc.bloglist.slice().reverse()" :key="i" class="blog-list-item"
                :href="data.link">
              <div class="blog-list-img">
                <img :src="data.poster" :alt="data.title">
              </div>
              <div class="blog-list-content">
                <div class="blog-list-title">
                  {{ data.title }}
                </div>
                <div class="blog-list-descript">
                  {{ data.desc }}
                </div>
                <div class="blog-list-date">
                  <!-- {{ $global.formatDate(new Date().getTime(), 'dd/MM/yyyy') }} -->
                  {{data.date}}
                </div>
              </div>
            </a>
          </div>
        </main>
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="https://gbwhatsapks.net/disclaimer/">Disclaimer</a>
            |
            <a href="https://gbwhatsapks.net/about-us/">About Us</a>
        </div>
        <div class="copyright-bar">
          2025 © All Rights Reserved <strong><a href="https://gbwhatsapks.net/">GBWhatsApp</a></strong>
        </div>
      </div>
    </footer>
  </div>
  <div v-if="$global.isMobileDevice()" style="height: 50px" aria-hidden="true" class="wp-block-spacer" />


  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" href="https://gbwhatsapks.net/download-gbwhatsapp/">
    Download GBWhatsapp APK
  </div>
</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import doc from '@/documents/blogList.js';
import Breadcrumb from '@/components/BreadCrumb.vue';

export default {
  name: 'Home',
  components: {
    Breadcrumb,
  },
  data() {
    return {
      pageName: 'home',
      showapk: false,

      apk: null,
      from: 'gb',
      filename: 'gb',
      // bloglist: [
      //   {
      //     title: 'How to download gb whatsapp for pc windows 10',
      //     poster: require('@/assets/How-to-download-gb-whatsapp-for-pc-windows-10.webp'),
      //     desc: "GB WhatsApp is a popular modified version of WhatsApp that offers several enhanced features not found in the official app. The GB WhatsApp PC version offers more features, enhanced customization",
      //     link: '/how-to-download-gb-whatsapp-pc-windows-10/',
      //   },
      //   {
      //     title: 'How to Transfer GB WhatsApp Messages to WhatsApp?',
      //     poster: require('@/assets/blog-1.webp'),
      //     desc: "If you are using WhatsApp and want to use the same account on a different phone, the answer is 'No' But if you are using GB WhatsApp and want to use the same account on a different phone, the answer is ",
      //     link: '/how-to-backup-gbwhatsapp/',
      //   },
      // ],
      doc: doc,
    };
  },
    computed: {
    breadcrumbs() {
      return this.$route.matched.reduce((acc, route) => {
        const crumbs = route.meta.breadcrumb || [];
        return acc.concat(crumbs);
      }, []);
    }
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }
  },
  methods: {
    goto(url) {
      window.location.href = url;
    },
    downloadURL() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },

    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatsapp.chat',
          appName: appname || 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    jump(url) {
      window.location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  color: #282828;
}
</style>

<style lang="scss" scoped>
#primary {
	min-height: 800px;
	padding: 0 50px;
}
.blog-list-row {
	width: 100%;
	margin: 50px auto;
}

.blog-list-item {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 60px;
	background: #fff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	padding:15px 20px 15px 0;

	.blog-list-img {
		flex: 0.45;
		margin-right: 24px;
		overflow: hidden;
		display: flex;
		align-items: center;
	}
	.blog-list-content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		.blog-list-title {
			font-size: 24px;
			font-family: Roboto-Bold, Roboto;
			font-weight: bold;
			color: #191919;
			line-height: 32px;
			margin-bottom: 10px;
		}
		.blog-list-descript {
			font-size: 16px;
			font-family: Roboto-Regular, Roboto;
			font-weight: 400;
			color: #666666;
			line-height: 30px;
			margin-bottom: 12px;
			overflow: hidden;
			display: -webkit-box;
			text-overflow: ellipsis;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}
		.blog-list-date {
			font-size: 14px;
			font-family: Roboto-Regular, Roboto;
			font-weight: 400;
			color: #999999;
			line-height: 25px;
		}
	}
}

@media (max-width:786px) {
	.blog-list-row {
		margin: 20px auto;
	}
	.blog-list-item {
		flex-direction: column;
		padding: 15px;
		gap:10px;
		.blog-list-img {
			margin: 0;
		}
		.blog-list-content {
			.blog-list-title {
				font-size: 19px;
				line-height: 27px;
				margin-bottom: 6px;
			}
			.blog-list-descript {
				font-size: 15px;
				line-height: 20px;
				margin-bottom: 8px;
			}
		}
	}
}
</style>